import DashboardLayout from "@/pages/layouts/DashboardLayout";

let PaymentMethodsRoutes = {
  path: '/payment_methods',
  component: DashboardLayout,
  redirect: '/payment_methods/list',
  children: [
    {
      path: 'list',
      name: 'Payment Methods List',
      component: () => import(/* webpackChunkName: "payment_methodS" */  '@/pages/paymentMethods/PaymentMethodList'),
      meta: {
        permissionsCodes: ['payment_methods/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Payment Methods Item',
      component: () => import(/* webpackChunkName: "payment_methodS" */  '@/pages/paymentMethods/PaymentMethodForm'),
      meta: {
        permissionsCodes: ['payment_methods/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Payment Methods Item',
      component: () => import(/* webpackChunkName: "payment_methodS" */  '@/pages/paymentMethods/PaymentMethodForm'),
      meta: {
        permissionsCodes: ['payment_methods/update'],
      }
    }
  ]
}

export default PaymentMethodsRoutes;
