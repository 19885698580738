import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import clientRoutes from "./clients/clientRoutes";
import avatarRoutes from "./avatars/avatarRoutes";
import jobRoutes from "./jobRequests/jobRoutes";
import jobRequestRoutes from "./jobRequests/jobRequestRoutes";
import ordersRoutes from "./orders/ordersRoutes";
import ShippingMethodsRoutes from "./shippingMethods/shippingMethodRoutes";
import PaymentMethodsRoutes from "./paymentMethods/paymentMethodRoutes";
import giftBasketRoutes from "./giftBaskets/giftBasketRoutes";
import couponRoutes from "./coupons/couponRoutes";
import CustomServiceRoutes from "./customServices/customServiceRoutes";
import CustomOrderRoutes from "./customOrders/customOrderRoutes";

let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  contactusRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  clientRoutes,
  avatarRoutes,
  jobRoutes,
  jobRequestRoutes,
  ordersRoutes,
  ShippingMethodsRoutes,
  PaymentMethodsRoutes,
  giftBasketRoutes,
  couponRoutes,
  CustomServiceRoutes,
  CustomOrderRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
