import DashboardLayout from "@/pages/layouts/DashboardLayout";

let ShippingMethodsRoutes = {
  path: '/shipping_methods',
  component: DashboardLayout,
  redirect: '/shipping_methods/list',
  children: [
    {
      path: 'list',
      name: 'Shipping Methods List',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/shippingMethods/ShippingMethodList'),
      meta: {
        permissionsCodes: ['shipping_methods/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Shipping Methods Item',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/shippingMethods/ShippingMethodForm'),
      meta: {
        permissionsCodes: ['shipping_methods/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Shipping Methods Item',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/shippingMethods/ShippingMethodForm'),
      meta: {
        permissionsCodes: ['shipping_methods/update'],
      }
    }
  ]
}

export default ShippingMethodsRoutes;
