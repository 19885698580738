import DashboardLayout from "@/pages/layouts/DashboardLayout";

let CustomServiceRoutes = {
  path: '/custom_services',
  component: DashboardLayout,
  redirect: '/custom_services/list',
  children: [
    {
      path: 'list',
      name: 'Custom Services List',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/customOrders/customServices/CustomServiceList'),
      meta: {
        permissionsCodes: ['custom_services/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Custom Services Item',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/customOrders/customServices/CustomServiceForm'),
      meta: {
        permissionsCodes: ['custom_services/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Custom Services Item',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/customOrders/customServices/CustomServiceForm'),
      meta: {
        permissionsCodes: ['custom_services/update'],
      }
    }
  ]
}

export default CustomServiceRoutes;
