import DashboardLayout from "@/pages/layouts/DashboardLayout";

let CustomOrderRoutes = {
  path: '/custom_orders',
  component: DashboardLayout,
  redirect: '/custom_orders/list',
  children: [
    {
      path: 'list',
      name: 'Custom Orders List',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/customOrders/CustomOrderList'),
      meta: {
        permissionsCodes: ['custom_orders/index'],
      }
    },
    {
      path: 'view/:id',
      name: 'View Custom Orders Item',
      component: () => import(/* webpackChunkName: "shipping_methodS" */  '@/pages/customOrders/CustomOrderView'),
      meta: {
        permissionsCodes: ['custom_orders/view'],
      }
    }
  ]
}

export default CustomOrderRoutes;
