import DashboardLayout from "@/pages/layouts/DashboardLayout";

let giftBasketRoutes = {
  path: '/gift_baskets',
  component: DashboardLayout,
  redirect: '/gift_baskets/list',
  children: [
    {
      path: 'list',
      name: 'Gift Basket List',
      component: () => import(/* webpackChunkName: "gift_baskets" */  '@/pages/giftBaskets/GiftBasketList'),
      meta: {
        permissionsCodes: ['gift_baskets/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Gift Basket',
      component: () => import(/* webpackChunkName: "gift_baskets" */  '@/pages/giftBaskets/GiftBasketForm'),
      meta: {
        permissionsCodes: ['gift_baskets/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Gift Basket',
      component: () => import(/* webpackChunkName: "gift_baskets" */  '@/pages/giftBaskets/GiftBasketForm'),
      meta: {
        permissionsCodes: ['gift_baskets/update'],
      }
    }
  ]
}

export default giftBasketRoutes;
