import DashboardLayout from "@/pages/layouts/DashboardLayout";

let ordersRoutes = {
  path: '/orders',
  component: DashboardLayout,
  redirect: '/orders/list',
  children: [
    {
      path: 'list',
      name: 'Orders List',
      component: ()=>import(/* webpackChunkName: "orders" */  '@/pages/orders/OrderList'),
      meta: {
        permissionsCodes: ['orders/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Order Details',
      component:()=>import(/* webpackChunkName: "Job-requests" */ '@/pages/orders/OrderView'),
      meta: {
        permissionsCodes: ['orders/get'],
      }
    },
  ]
}

export default ordersRoutes;
